import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "item", "empty"]

  connect() {
    this.toggleItems()
    document.addEventListener('content:loaded', () => this.toggleItems())
  }

  toggle() {
    this.toggleItems()
  }

  toggleItems() {
    const showMissedOnly = this.checkboxTarget.checked;
    let hasVisibleMissedStandards = false;

    this.itemTargets.forEach(item => {
      const isMissed = item.dataset.standardMissed === "true";

      if (showMissedOnly) {
        const shouldShow = isMissed || this.hasNestedMissedStandard(item);
        item.hidden = !shouldShow;
        if (shouldShow) hasVisibleMissedStandards = true;
      } else {
        item.hidden = false;
        if (isMissed) hasVisibleMissedStandards = true;
      }
    });

    // Show the empty message only if the checkbox is checked and no missed standards are visible
    this.emptyTarget.hidden = !showMissedOnly || hasVisibleMissedStandards;
  }

  hasNestedMissedStandard(element) {
    return element.querySelector('[data-standard-missed="true"]') !== null;
  }
}
